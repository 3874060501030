"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SSO_LOGOUT_PAGE_URL = exports.SSO_REGISTER_PAGE_URL = exports.SSO_LOGIN_INTERNAL_PAGE_URL = exports.SSO_LOGIN_PAGE_URL = exports.SSO_WEB_BASE_URL = exports.getBaseUrl = exports.DevPort = void 0;
// 本地开发使用的端口
exports.DevPort = {
    api: 4000,
    chat: 3000,
    cms: 3001,
    sso: 3002,
    chatLite: 3003,
    'smart-writer': 3004,
    tcm: 3005,
    landingPage: 3006,
};
/**
 * 获取目标应用BaseURL
 * @param appName 应用名称
 * @param devPort 开发阶段使用的端口
 * @returns 目标应用BaseURL
 */
function getBaseUrl(appName, devPort = exports.DevPort[appName] ?? 80) {
    if (!globalThis.location) {
        return `http://localhost:${devPort}`;
    }
    // 主机名，本地开发统一使用localhost
    const { hostname } = globalThis.location;
    // 本地开发环境
    if (hostname.endsWith('localhost')) {
        return `http://localhost:${devPort}`;
    }
    // 线上环境(包含正式环境、预览环境等等)
    const apiHostname = [appName, ...hostname.split('.').slice(1)].join('.');
    return `https://${apiHostname}`;
}
exports.getBaseUrl = getBaseUrl;
exports.SSO_WEB_BASE_URL = getBaseUrl('sso');
exports.SSO_LOGIN_PAGE_URL = `${exports.SSO_WEB_BASE_URL}/login`;
exports.SSO_LOGIN_INTERNAL_PAGE_URL = `${exports.SSO_WEB_BASE_URL}/login/internal`;
exports.SSO_REGISTER_PAGE_URL = `${exports.SSO_WEB_BASE_URL}/register`;
exports.SSO_LOGOUT_PAGE_URL = `${exports.SSO_WEB_BASE_URL}/logout`;
