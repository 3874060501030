import { BaseService } from './base.service.js';
import { timeConverter } from '@ai-agent/util';
class SessionService extends BaseService {
    async getPreviewSession() {
        const { data: session } = await this.fetch('/preview/session');
        return session;
    }
    async getCurrentSessions() {
        const res = await this.fetch('/session/current-user');
        return res.data.map(timeConverter);
    }
    async createSession(dto) {
        const res = await this.fetch('/session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return timeConverter(res.data);
    }
    async createTCMSession() {
        const res = await this.fetch('/session/tcm', {
            method: 'POST',
        });
        return timeConverter(res.data);
    }
    async createSessionByAccount(account) {
        const res = await this.fetch(`/session/by-account/${encodeURIComponent(account)}`, {
            method: 'POST',
        });
        return timeConverter(res.data);
    }
    async getSessions(ids) {
        const res = await this.fetch(`/session?ids=${ids.join(',')}`);
        return res.data.map(timeConverter);
    }
    async getSessionMember(id) {
        const res = await this.fetch(`/session/${id}/member`);
        return res.data;
    }
    async getSessionsMember(ids) {
        const { data } = await this.fetch(`/session/${ids.join(',')}/members`);
        return data;
    }
    async addUsers(sessionId, dto) {
        return this.fetch(`/session/${sessionId}/member`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async update(sessionId, dto) {
        const res = await this.fetch(`/session/${sessionId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return timeConverter(res.data);
    }
    async dismiss(sessionId) {
        return this.fetch(`/session/${sessionId}`, {
            method: 'DELETE',
        });
    }
    async removeUser(sessionId, dto) {
        return this.fetch(`/session/${sessionId}/remove`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async pin(sessionId) {
        const res = await this.fetch(`/session/${sessionId}/pin`, {
            method: 'PUT',
        });
        return timeConverter(res.data);
    }
    async getOrder() {
        const res = await this.fetch('/session/order');
        return res.data.map(timeConverter);
    }
    async clearHistory(sessionId) {
        return this.fetch(`/session/${sessionId}/clear`, {
            method: 'POST',
        });
    }
    async clearContext(sessionId) {
        return this.fetch(`/session/${sessionId}/clear-context`, {
            method: 'POST',
        });
    }
    async getMessageIds(sessionId) {
        const res = await this.fetch(`/session/${sessionId}/message-ids`);
        return res.data;
    }
    async filterAssets(dto) {
        const res = await this.fetch('/session/asset/filter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return res.data.map(timeConverter);
    }
    async createAsset(attr) {
        const res = await this.fetch('/session/asset', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(attr),
        });
        return timeConverter(res.data);
    }
    async updateAsset(assetId, dto) {
        const res = await this.fetch(`/session/asset/${assetId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return timeConverter(res.data);
    }
    async removeAsset(assetId) {
        await this.fetch(`/session/asset/${assetId}`, {
            method: 'DELETE',
        });
    }
    async importProtocolsWithContext(sessionId) {
        return this.fetch(`/session/asset/import-protocols-with-context?sessionId=${sessionId}`, {
            method: 'GET',
        });
    }
    async unselectAllAssets(sessionId) {
        return this.fetch(`/session/asset/unselect-all?sessionId=${sessionId}`, {
            method: 'POST',
        });
    }
    async filter(dto) {
        const res = await this.fetch('/session/filter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        res.data = res.data.map(timeConverter);
        return res;
    }
    async getAllTemplates() {
        const { data: templates } = await this.fetch('/session/template');
        return templates.map(timeConverter);
    }
    async createTemplate(dto) {
        const res = await this.fetch('/session/template', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return timeConverter(res.data);
    }
    async updateTemplate(id, dto) {
        const res = await this.fetch(`/session/template/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return timeConverter(res.data);
    }
    async removeTemplate(id) {
        await this.fetch(`/session/template/${id}`, {
            method: 'DELETE',
        });
    }
    async getSessionTemplateUsers(id) {
        const { data: users } = await this.fetch(`/session/template/${id}/users`);
        return users;
    }
    async updateSessionTemplateUsers(id, userIds) {
        await this.fetch(`/session/template/${id}/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userIds),
        });
    }
}
export const sessionService = new SessionService();
